<template>
  <div
    v-click-outside="resetData"
    class="pp-entity__header"
    :class="{ 
      'pp-entity__header--open' : itemsCount,
      'pp-entity__header--empty' : searchEmpty
    }"
  >
    <gl-search-box
      v-model="search"
      button-text="Search"
      dark-clear
      :disabled="loading || !search"
      grey
      height="32px"
      :independent="isMobileInclude ? true : false"
      is-search
      placeholder="Enter the entity name"
      @clear="resetData"
      @search="loadData"
    />
    <div class="pp-entity__header-dropdown">
      <div class="pp-entity__header-dropdown-wrap">
        <div
          v-for="entityItem in searchResult"
          :key="entityItem.id"
          class="pp-entity__header-dropdown-item"
          @click="updateSelected(entityItem)"
        >
          <div class="pp-entity__header-dropdown-item-name">
            {{ entityItem.name }}
          </div>
          <div class="pp-entity__header-dropdown-item-type">
            <GlTag
              :score="entityItem?.type?.score"
              :tag="entityItem?.type?.name"
            />
          </div>
        </div>
      </div>
    </div>
    <div class="pp-entity__header-dropdown-empty">
      <div>The search did not return any results</div>
    </div>
  </div>
</template>

<script>
// Components
import GlSearchBox from "@/components/gl-search-box"
import GlTag from '@/components/gl-tag'

// Mixins
import deviceWidthMixin from '@/assets/mixins/deviceWidthMixin'

// Vuex
import { mapActions } from 'vuex'

// Libs
import _ from 'lodash'

// Utils
import { getFirstRouteName } from '@/utils/get-route-name'

// Directives
import vClickOutside from 'v-click-outside'

const initialState = {
  search: '',
  loading: false,
  searchResult: null,
  itemsCount: 0,
  searchEmpty: false,
}

export default {
  components: {
    GlSearchBox,
    GlTag,
  },
  directives: {
    clickOutside: vClickOutside.directive
  },
  mixins: [deviceWidthMixin],
  data() {
    return {
      ...initialState
    }
  },
  watch: {
    search: {
      handler() {
        this.searchDebounce()
      },
    },
  },
  methods: {
    getFirstRouteName,
    ...mapActions({
      getEntityList: 'entity/getEntityList',
    }),
    async loadData() {
      this.loading = true

      await this.getEntityList({
        searchAutocomplete: this.search,
      })
        .then(({ items, count }) => {
          if(count) {
            this.searchEmpty = false
            this.itemsCount = count
            this.searchResult = items
          } else {
            this.itemsCount = 0
            this.searchEmpty = true
          }
        })
        .catch(e => {
          this.$toasted.global.error({ message: e })
        })
        .finally(() => {
          this.loading = false
        })    
    },
    async updateSelected(event) {
      await this.resetData()

      if (
        this.$route.name === 'entity-explorer' ||
        this.getFirstRouteName(this.$route) === 'entity-explorer'
      ) {
        this.$router.push({
          name: 'view-entity-explorer',
          params: { entityId: event.id },
        })
      }
      if (
        this.$route.name === 'entity-management' ||
        this.getFirstRouteName(this.$route) === 'entity-management'
      ) {
        this.$router.push({
          name: 'view-entity-management',
          params: { entityId: event.id },
        })
      }
    },
    searchDebounce: _.debounce(function () {
      this.search.trim() ? this.loadData() : this.resetData()
    }, 500),
    resetData() {
      this.itemsCount = 0

      return new Promise((resolve) => {
        setTimeout(() => {
          Object.assign(this.$data, initialState)
          resolve()
        }, 300)
      });
    }
  }
}
</script>

<style scoped>
.pp-entity__header {
  margin-bottom: 16px;
  padding: 24px;
  background-color: var(--white);
  border-radius: 12px;
  position: relative;
  z-index: 5;
  transition: box-shadow 0.3s ease;
}
.pp-entity__header--open,
.pp-entity__header--empty {
  box-shadow: var(--shadow);
}
.pp-entity__header::v-deep .gl-search-box .gl-input__input {
    min-width: 100px !important;
    border-radius: 6px;
}

.pp-entity__header-dropdown,
.pp-entity__header-dropdown-empty {
  opacity: 0;
  display: grid;
  grid-template-rows: 1fr;
  width: 100%;
  max-height: 298px;
  padding: 0 24px 24px 24px;
  background-color: var(--white);
  border-radius: 0 0 12px 12px;
  box-shadow: var(--shadow);
  position: absolute;
  left: 0;
  top: 100%;
  transform: translate(0, -10px);
  transition: opacity 0.3s ease, transform 0.3s ease;
  pointer-events: none;
}
.pp-entity__header-dropdown-empty {
  font-size: 14px;
  color: var(--dark-grey);
  text-align: center;
}
.pp-entity__header-dropdown:before,
.pp-entity__header-dropdown-empty:before {
  content: "";
  width: 100%;
  height: 8px;
  background-color: var(--white);
  position: absolute;
  left: 0;
  top: -8px;
}
.pp-entity__header--open .pp-entity__header-dropdown {
  opacity: 1;
  transform: translate(0, 0);
  pointer-events: all;
}
.pp-entity__header--empty .pp-entity__header-dropdown-empty {
  opacity: 1;
  transform: translate(0, 0);
  pointer-events: all;
}
.pp-entity__header-dropdown-wrap {
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 16px;
  overflow: auto;
}
.pp-entity__header-dropdown-wrap::-webkit-scrollbar {
  width: 8px;
}
.pp-entity__header-dropdown-item {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 16px;
  align-items: center;
  padding: 8px;
  border-bottom: 1px solid var(--cotton-grey-f-5);
  cursor: pointer;
  transition: background-color 0.3s ease;
}
.pp-entity__header-dropdown-item-name,
.pp-entity__header-dropdown-item-type {
  min-width: 50%;
}
.pp-entity__header-dropdown-item-name {
  font-size: 12px;
  line-height: 1;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.pp-entity__header-dropdown-item:hover {
  background-color: var(--cotton-grey-f-5);
}

@media (max-width: 1023px) {
  .pp-entity__header-dropdown-wrap {
    grid-template-columns: 1fr;
  }
}
@media (max-width: 767px) {
  .pp-entity__header {
    margin-bottom: 0;
    padding: 16px 16px 8px 16px;
    border-radius: 0;
  }
  .pp-entity__header .gl-search-box {
    overflow: hidden;
  }
}
</style>